export default {
    body: `
  <article style="list-style:none; text-align: left; font-size: 18px">
    <h1 style="font-size: 26px; font-style: bold; padding-bottom: 20px;">
        129+ World of Warcraft Books in Order | Reading / Chronological List 2020!
    </h1>
    <h2 style="font-size: 16px; font-style: italic; padding-bottom: 20px; line-height: 19px;">
       <strong>Disclaimer: </strong>After many requests I have dig out our Warcraft Books list from the archives and I have reposted it. With that being said, I want to mention that I do this for legacy reasons, and multiple people asking me for it, so there might be broken links, some mistakes, some unupdated stuffs, and more. I might update it in future, I might not. If someone want to go with that and update it, please let me know we'll figure it out. Here it is, enjoy!
    </h2>

    <div itemprop="text">
      <p>
        <strong>Imagine</strong> if someone could give you a
        <strong>List of</strong>&nbsp;<strong>ALL the Warcraft Books</strong>
        <strong>&amp; Novels</strong>, and all you have to do is just obtain,
        follow along, don’t think which one goes first, which second?
      </p>
      <p>or even better…</p>
      <p>
        What if you could have a List of all the&nbsp;<strong>
          World of Warcraft Books, Novels, Audio, Video, Free PDFs,</strong
        >
        and&nbsp;<strong>even beyond that,</strong>&nbsp;that is Sorted in
        Chronological | Reading Order?
      </p>
      <p>
        <strong>You have all of that</strong> in
        <strong>This Collection,&nbsp;</strong>plus&nbsp;couple
        <strong>extra bonuses</strong> on my tab, like:
      </p>
      <ul style="list-style: none">
        <li>
          <span style="color: #993300"
            ><strong>c&nbsp;</strong
            ><strong
              ><span style="color: #ff6600"
                >o&nbsp;<span style="color: #ff0000"
                  >l&nbsp;<span style="color: #ff00ff"
                    >o&nbsp;<span style="color: #800080">r&nbsp;</span></span
                  ></span
                ></span
              ></strong
            ></span
          >coding that all, so you know&nbsp;<strong>exactly</strong> what type
          of resource you looking at.
        </li>
        <li>
          Line them up by Years (<strong>Chronological | Reading Order</strong>)
        </li>
        <li>
          <strong>DIRECT&nbsp;</strong>links to the easiest &amp; cheapest
          (If&nbsp;<strong>free</strong> doesn’t apply) way to get the resources
        </li>
        <li>
          <strong>Providing you Support</strong> on the way&nbsp; [Just let me
          know down in the comments]
        </li>
        <li>
          Coded Guide on How to Obtain the Books Cheapest way by buying the
          collections that are available, instead of 1 by 1.
        </li>
        <li>
          <strong>World of Warcraft</strong>
          <strong>Collection&nbsp;</strong>that is stripped of all
          the&nbsp;<strong>BS,&nbsp;</strong>and straight to the point. –<em
            >I don’t want to waste your time, I know you don’t want either.</em
          >
        </li>
      </ul>
      <p>
        <em
          ><strong>Note:&nbsp;</strong>I will add a video tutorial on what some
          of the markings means in the following days, make sure to subscribe to
          the
          <a
            href="http://youtube.com/umrentv"
            target="_blank"
            rel="noopener noreferrer"
            >YouTube</a
          >, if you havent’ already, to get notified.</em
        >
      </p>
      <h3><strong>Lets Go:</strong></h3>
      <p>
        <strong><u>Beginning of the World:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2Jwd13e">Warcraft Chronicle v.1</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)&nbsp;–
          <a href="https://amzn.to/2Jwd13e">US</a>
          |
          <a href="https://amzn.to/2tf1X0d">UK</a>
          |
          <a href="https://amzn.to/2MBdYWt">CA</a>
          |
          <a href="https://amzn.to/2M1Y72m">DE</a>
          |
          <a href="https://amzn.to/2LX7CzJ">FR</a>
          |
          <a href="https://amzn.to/2I2FDfO">IT</a>
          |
          <a href="https://amzn.to/2lj1u9Q">ES</a>
        </li>
        <li>
          <a href="https://amzn.to/2sKPYr3">Warcraft Chronicle v.2</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon –
          <a href="https://amzn.to/2sKPYr3">US</a>
          |
          <a href="https://amzn.to/2t1Zwij">UK</a>
          |
          <a href="https://amzn.to/2LX3dwL">CA</a>
          |
          <a href="https://amzn.to/2tgEBas">DE</a>
          |
          <a href="https://amzn.to/2t3VEgu">FR</a>
          |
          <a href="https://amzn.to/2M0XkyB">IT</a>
          |
          <a href="https://amzn.to/2K0dWcs">ES</a>
        </li>
        <li>
          <a href="https://amzn.to/2Hp9Xk5">Warcraft Chronicle v.3</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon) –
          <a href="https://amzn.to/2Hp9Xk5">US</a>
          |
          <a href="https://amzn.to/2leo5nU">UK</a>
          |
          <a href="https://amzn.to/2M3bvD5">CA</a>
          |
          <a href="https://amzn.to/2M3uC0b">DE</a>
          |
          <a href="https://amzn.to/2tjnmW9">FR</a>
          |
          <a href="https://amzn.to/2tf3lzX">IT</a>
          |
          <a href="https://amzn.to/2I0eslD">ES</a>
        </li>
        <li>
          <a href="https://amzn.to/2szOaBU">Ultimate Visual Guide</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon) –
          <a href="https://amzn.to/2szOaBU">US</a>
          |
          <a href="https://amzn.to/2JXpNb5">UK</a>
          |
          <a href="https://amzn.to/2M0Yx97">CA</a>
          |
          <a href="https://amzn.to/2MBLITO">DE</a>
          |
          <a href="https://amzn.to/2lhvuCZ">FR</a>
          |
          <a href="https://amzn.to/2M32slV">IT</a>
          |
          <a href="https://amzn.to/2LXaahh">ES</a>
        </li>
      </ul>
      <p>
        <strong><u>-13,000:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/death-from-above"
            >Death from Above</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Free)
        </li>
      </ul>
      <p>
        <strong><u>-10000:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://www.youtube.com/watch?v=-D--GWwca0g"
            >Burdens of Shaohao: The Vision</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=afpRzcAAZVM"
            >Part 1: Doubt</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=gMEHZPZTAVc"
            >Part 2: Despair</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=lom_plwy9iA">Part 3: Fear</a>
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=BSWYMQEQhEo"
            >Part 4: Anger</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>
        <strong><u>-2600:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2M0MtWl"
            >The First Guardian / Warcraft: Legends Volume 5</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*5</strong> –
          <a
            href="https://amzn.to/2M0MtWl"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiYv4B"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M3GZcn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lmBIl7"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2liq9uY"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a href="https://amazon.it" target="_blank" rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2lhCwHD"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>-45:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2sJa7gX"
            >A Warrior Made / Warcraft: Legends Volume 4</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*4</strong>&nbsp;–
          <a
            href="https://amzn.to/2sJa7gX"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiJqQI"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2tlB7mW"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K6xvA0"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K2EYzO"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2lk5irq"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t8jnfR"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>-44 to -45:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/F0NUX9CIKEY31424200903205.pdf"
            >Blood and Thunder</a
          >
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel </strong></span
          >(Free)
        </li>
      </ul>
      <p>
        <strong><u>-1:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2xM2DzF">Rise of the Horde</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon)-
          <a
            href="https://amzn.to/2xM2DzF"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ypOx7i"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2K1Pq7H"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K1PA26"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K6HBko"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2I5KnRS"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I5Kwoo"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://worldofwarcraft.com/en-us/story/short-story/unbroken"
            >Unbroken</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Free)
        </li>
        <li>
          <a href="https://amzn.to/2LmiPJV"
            >Family Values – Warcraft: Legends Volume 2</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*2&nbsp;</strong>–
          <a
            href="https://amzn.to/2LmiPJV"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ytJtyG"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JSTnQ4"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2HZQBTa"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K6xHze"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tl3922"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t3Xxdl"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>0:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2JjUXG4"
            >Warcraft 1 Campaign: Orcs &amp; Humans</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Amazon)&nbsp;– (Same for All Regions, since it’s Digital Product)
        </li>
        <li>
          <a href="https://amzn.to/2sJCewG">Warcraft</a>
          –
          <span style="color: #0000ff"><strong>Movie </strong></span>(Amazon) –
          <a
            href="https://amzn.to/2sJCewG"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yn48V5"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t6rUjq"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JZGqD1"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2lfDPH8"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2K2s98M"
            target="_blank"
            rel="noopener noreferrer"
            >IT1</a
          >
          /
          <a
            href="https://amzn.to/2t3YUJ1"
            target="_blank"
            rel="noopener noreferrer"
            >IT2</a
          >
          |
          <a
            href="https://amzn.to/2lh2bQP"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>3:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2HlDfjO"
            >The Last Guardian&nbsp;/ Warcraft Archive (World of Warcraft)</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)<strong>*6&nbsp;</strong>–
          <a
            href="https://amzn.to/2HlDfjO"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yo0wlZ"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7joAn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K5PHtl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JZ8vun"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2t97LJf"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K4u9Ny"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2xM00xB"
            >Blood of the Highborne [Chapter 1]&nbsp;/ Paragons</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Amazon)<strong>*7&nbsp;</strong>–
          <a
            href="https://amzn.to/2xM00xB"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JNimUx"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ljVYn2"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkneFX"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2tlsCZd"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tk7D93"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K5gtSw"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=ZXiGt2PxoNg"
            >The Story of Warcraft: Orcs &amp; Humans #1</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>
        <strong><u>5:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2xMe0aM">Tides of Darkness</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon) –
          <a
            href="https://amzn.to/2xMe0aM"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2MCGaZ6"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M4fNue"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JMDjPH"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2thXnhR"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2K6Arg0"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2JMDMkV"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>6:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2Lraapw"
            >Warcraft 2 Campaign: Tides of Darkness</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span>(Amazon)
          *<strong>10&nbsp;</strong>– (Same for All Regions, since it’s Digital
          Product)
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=UzaW3G-R9R4"
            >The Story of Warcraft: Tides of Darkness</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>
        <strong><u>8:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2Lraapw"
            >Warcraft 2 Campaign: Beyond the Dark Portal</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Amazon)<strong>*10</strong> – (Same for All Regions, since it’s
          Digital Product)
        </li>
        <li>
          <a href="https://amzn.to/2xKOnHn">Beyond the Dark Portal</a>
          –
          <span style="color: #993300"><strong>Novel</strong></span>
          (Amazon) –
          <a
            href="https://amzn.to/2xKOnHn"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JX9qeT"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ynLsVl"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K1RXSp"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K1Sfst"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2MFGCpI"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tjE4oo"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=dQLxcTLZSDc"
            >The Story of Warcraft: Beyond the Dark Portal</a
          >
          –<span style="color: #ff0000"> <strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>
        <strong><u>10:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2HlDfjO"
            >Day of the Dragon&nbsp;/ Warcraft Archive (World of Warcraft)</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)<strong>*6&nbsp;</strong>–
          <a
            href="https://amzn.to/2HlDfjO"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yo0wlZ"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7joAn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K5PHtl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JZ8vun"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2t97LJf"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K4u9Ny"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>18:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2HlDfjO"
            >Lord of the Clans / Warcraft Archive (World of Warcraft)</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)<strong>*6 </strong>–
          <a
            href="https://amzn.to/2HlDfjO"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yo0wlZ"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7joAn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K5PHtl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JZ8vun"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2t97LJf"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K4u9Ny"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/road-to-damnation"
            >Road to Damnation</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Free)
        </li>
      </ul>
      <p>
        <strong><u>19:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2HlDfjO"
            >Of Blood and Honor / Warcraft Archive (World of Warcraft)</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)<strong>*6</strong>–
          <a
            href="https://amzn.to/2HlDfjO"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yo0wlZ"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7joAn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K5PHtl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JZ8vun"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2t97LJf"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K4u9Ny"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>20:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2xMWYcv"
            >Warcraft 3 Campaign: Reign of Chaos</a
          >
          –
          <span style="color: #008000"><strong>Game</strong></span>
          (Amazon)<strong>*9</strong>&nbsp;or (<a
            href="https://eu.shop.battle.net/en-us/family/warcraft-iii"
            >Blizzard Shop</a
          >&nbsp;&lt;- this is link to Blizz) – Check both Prices, Amazon and
          Blizz. Since Amazon Link is Both Expansions (this and The Frozen
          Throne) as bundle, you might find them for half the price than Blizz
          shop. –&nbsp;<strong>No Regions Too</strong> for Amazon, it’s Digital
          Product
        </li>
        <li>
          <a href="https://amzn.to/2xMKaTx">Arthas: Rise of the Lich King</a>
          –
          <span style="color: #993300"><strong>Novel</strong></span>
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2xMKaTx"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2t8mrbR"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MDTg8H"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2ypJVhu"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2t6tcLi"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tlxj5e"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t6thi4"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/voljin"
            >Vol’jin: The Judgement</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span> </strong
          >(Free)
        </li>
        <li>
          <a href="https://amzn.to/2Jtitnl">Death Knight</a>
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel</strong></span
          >
          (Amazon)-
          <a
            href="https://amzn.to/2Jtitnl"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JZ9Q4n"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2yo1TB9"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2yo1UFd"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2tiWFRi"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tosLLI"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2yoNaFT"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=_AH8Nl7Ehjo"
            >The Story of Warcraft: Reign of Chaos</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a href="https://amzn.to/2xM00xB"
            >Blood of the Highborne [Chapter 2 and 3] / Paragons</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Amazon)<strong>*7&nbsp;</strong>–
          <a
            href="https://amzn.to/2xM00xB"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JNimUx"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ljVYn2"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkneFX"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2tlsCZd"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tk7D93"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K5gtSw"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2xJ7py0"
            >A Cleansing Fire / Warcraft: Legends Volume 5</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel</strong></span
          >&nbsp;(Amazon)<strong>*5&nbsp;</strong>–
          <a
            href="https://amzn.to/2M0MtWl"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiYv4B"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M3GZcn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lmBIl7"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2liq9uY"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a href="http://amazon.it/" target="_blank" rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2lhCwHD"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>22:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2JcsdmA"
            >Warcraft 3 Campaign: The Frozen Throne</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Amazon)<strong>*9 or&nbsp;</strong>(<a
            href="https://eu.shop.battle.net/en-us/family/warcraft-iii"
            >Blizzard Shop</a
          >&nbsp;&lt;- this is link to Blizz) – The Amazon link on the start is
          different than above. Sometimes this listing has cheaper prices,
          sometimes the other one. Check them both. If not Blizz shop.
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=-cxaFPpb9zY"
            >The Story of Warcraft: The Frozen Throne</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>
        <strong><u>23:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2kOwcHk"
            >The Well of Eternity / Warcraft: War of the Ancients Archive</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)<strong>*0&nbsp;</strong>–
          <a
            href="https://amzn.to/2kOwcHk"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ymPgWO"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2lgzMu2"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JYE8UV"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2M55ztz"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tqtn3t"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K1TfNf"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2kOwcHk"
            >The Demon Soul / Warcraft: War of the Ancients Archive</a
          >&nbsp;–
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)<strong>*0&nbsp;</strong>–
          <a
            href="https://amzn.to/2kOwcHk"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ymPgWO"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2lgzMu2"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JYE8UV"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2M55ztz"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tqtn3t"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K1TfNf"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2kOwcHk"
            >The Sundering&nbsp;/ Warcraft: War of the Ancients Archive</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)<strong>*0&nbsp;</strong>–
          <a
            href="https://amzn.to/2kOwcHk"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ymPgWO"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2lgzMu2"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JYE8UV"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2M55ztz"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tqtn3t"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K1TfNf"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2M0WDGt">Ashbringer</a>
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel</strong></span
          >
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2M0WDGt"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yjtNy2"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2tlyu4E"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tlyFgk"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K0cCpP"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2yq5GxS"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tkjVyn"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2JtkCPV">Sunwell Trilogy</a>
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel</strong>&nbsp;<strong
              >Collection</strong
            ></span
          >
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2JtkCPV"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yxuZyb"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7kNqO"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2ypRyEM"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2I5GpZu"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tj4feF"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tj4YfT"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>24:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://amzn.to/2xLYoEf">Cycle of Hatred</a>
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon) –
          <a
            href="https://amzn.to/2xLYoEf"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tkptZM"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MDIVts"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tiZjXe"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2yxvN6b"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2yoOZmd"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2ynONnl"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>25:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://eu.shop.battle.net/en-us/product/world-of-warcraft"
            >World of Warcraft: Classic / Vanilla</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>*8 – </strong>I highly recommend to check&nbsp;<a
            href="https://www.g2a.com/r/umren"
            ><strong>G2A</strong></a
          ><strong>&nbsp;</strong>for battlechest prices too. BattleChest means
          you have couple expansions together. You can’t buy them separately
          anymore. G2A has most of the time better prices than Blizzard, just
          make sure you scroll down to check other sellers prices for the same
          product. Also make sure you check the region for which you buy the
          key. Here is:&nbsp;<strong
            ><a href="https://www.g2a.com/r/umren-battlechest-us"
              >Battlechest for US</a
            >&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/umren-battlechest-eu"
            ><strong>Battlechest for EU</strong></a
          >
        </li>
        <li>
          <a href="https://wow.gamepedia.com/The_War_Effort">The War Effort</a>
          –
          <strong><span style="color: #ff9900">Short Story</span> </strong
          >(Free)
        </li>
        <li>
          <a href="https://amzn.to/2JyBSU3"
            >The Thrill of the Hunt / Warcraft: Legends Volume 3</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon) –
          <a href="https://amzn.to/2JyBSU3">US</a>
          |
          <a
            href="https://amzn.to/2M3aYkM"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a href="https://amzn.to/2JMQRut">CA</a>
          |
          <a href="https://amzn.to/2tiSxRq">DE</a>
          |
          <a href="https://amzn.to/2JYH7wB">FR</a>
          |
          <a href="https://amzn.to/2ti0CWz">IT</a>
          |
          <a href="https://amzn.to/2K6FIEk">ES</a>
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/war-of-the-shifting-sands?cmsMode=live"
            >War of Shifting Sands</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Free)
        </li>
        <li>
          <a href="https://amzn.to/2HppDUu"
            >Blodsail Bucaneer&nbsp;/ Warcraft: Legends Volume 4</a
          >&nbsp;–
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel</strong></span
          >
          (Amazon)<strong>*4&nbsp;</strong>–
          <a
            href="https://amzn.to/2sJa7gX"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiJqQI"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2tlB7mW"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K6xvA0"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K2EYzO"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2lk5irq"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t8jnfR"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2HodYWf"
            >How to Win Friends / Warcraft: Legends Volume 1</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*1&nbsp;</strong>–
          <a
            href="https://amzn.to/2HodYWf"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2lgUCcR"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MzWVnL"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tl1IRl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2thgQPD"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M4J87w"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I2YxTB"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2sK9Ie9"
            >Miles to Go / Warcraft: Legends Volume 2</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*2&nbsp;</strong>–
          <a
            href="https://amzn.to/2LmiPJV"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ytJtyG"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JSTnQ4"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2HZQBTa"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K6xHze"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tl3922"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t3Xxdl"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2HodYWf"
            >An Honest Trade&nbsp;/ Warcraft: Legends Volume 1</a
          >–
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*1&nbsp;</strong>–&nbsp;<a
            href="https://amzn.to/2HodYWf"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2lgUCcR"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MzWVnL"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tl1IRl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2thgQPD"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M4J87w"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I2YxTB"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2sK9Ie9"
            >Warrior: Divided / Warcraft: Legends Volume 2</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*2&nbsp;</strong>–
          <a
            href="https://amzn.to/2LmiPJV"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ytJtyG"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JSTnQ4"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2HZQBTa"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K6xHze"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tl3922"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t3Xxdl"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2kRjd7N"
            target="_blank"
            rel="noopener noreferrer"
            >Warrior: United /&nbsp;Warcraft: Legends Volume 5</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*5&nbsp;</strong>–
          <a
            href="https://amzn.to/2M0MtWl"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiYv4B"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M3GZcn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lmBIl7"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2liq9uY"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a href="https://amazon.it" target="_blank" rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2lhCwHD"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>26:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://eu.shop.battle.net/en-us/product/world-of-warcraft"
            target="_blank"
            rel="noopener noreferrer"
            >World of Warcraft: The Burning Crusade</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>*8</strong>&nbsp;– Check the [<u>25:</u>] World of
          Warcraft: Classic / Vanila section for explanation<strong>
            -&gt;<a href="https://www.g2a.com/r/umren-battlechest-us"
              >Battlechest for US</a
            >&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/umren-battlechest-eu"
            ><strong>Battlechest for EU</strong></a
          >– Use Discount Code: <strong>UMREN</strong> at checkout for lowest
          price!
        </li>
        <li>
          <a
            href="https://amzn.to/2xM00xB"
            target="_blank"
            rel="noopener noreferrer"
            >Blood of the Highborne [Chapter 4] / Paragons</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Amazon)<strong>*7&nbsp;</strong>–
          <a
            href="https://amzn.to/2xM00xB"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JNimUx"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ljVYn2"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkneFX"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2tlsCZd"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tk7D93"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K5gtSw"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2JvGBWx"
            target="_blank"
            rel="noopener noreferrer"
            >World of Warcraft v1 (#1-6)</a
          >
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel</strong></span
          >
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2JvGBWx"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yoTRYx"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MF0sl1"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lgWVwx"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2lkezje"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M1vZME"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2lkf392"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2JlGMQM"
            target="_blank"
            rel="noopener noreferrer"
            >World of Warcraft v2 (#7-13)</a
          >
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel </strong></span
          >(Amazon) –
          <a
            href="https://amzn.to/2JlGMQM"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tkuWjk"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t9whtR"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2M3eUC4"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2I0KDBy"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2K4VjUO"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K1XrJt"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2HpoDjb"
            target="_blank"
            rel="noopener noreferrer"
            >World of Warcraft v3 (#14-19)</a
          >
          –
          <strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2HpoDjb"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I32d7R"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2tlEMBi"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2I1RJWu"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2yxzvN9"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2yo6WBB"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tlAMAP"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2JQJD8Q"
            target="_blank"
            rel="noopener noreferrer"
            >World of Warcraft v4 (#20-25)</a
          >
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel</strong></span
          >
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2JQJD8Q"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I1Y0kN"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2K1lzzk"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkqEs7"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JZqP3h"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M5Fef0"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tgRCRq"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2xN3Syr"
            target="_blank"
            rel="noopener noreferrer"
            >Shadow Wing v1</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel</strong></span
          >&nbsp;(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2xN3Syr"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tlB5vt"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M4XULA"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lgF6O2"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2I04D7i"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2I04v7O"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2yndJv5"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2xLzRPP"
            target="_blank"
            rel="noopener noreferrer"
            >Shadow Wing v2</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2xLzRPP"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tkrLbh"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7tqS3"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lkJwni"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2M5wci3"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2ykOOZq"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2M2OvUP"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2HodYWf"
            >The Journey / Warcraft: Legends Volume 1</a
          >
          –
          <strong
            ><span style="color: #800080">Manga / Graphic Novel</span
            >&nbsp;</strong
          >(Amazon)<strong>*1&nbsp;</strong>–
          <a
            href="https://amzn.to/2HodYWf"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2lgUCcR"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MzWVnL"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tl1IRl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2thgQPD"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M4J87w"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I2YxTB"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2JyBSU3"
            target="_blank"
            rel="noopener noreferrer"
            >Crusader’s Blood /&nbsp;Warcraft: Legends Volume 3</a
          >
          –
          <strong
            ><span style="color: #800080">Manga / Graphic Novel</span
            >&nbsp;</strong
          >(Amazon)<strong>*3&nbsp;</strong>–
          <a
            href="https://amzn.to/2JyBSU3"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JVJT2o"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2I3oCSl"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkszNl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2lhIcl2"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2K0uaix"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2ynTTQw"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2HppDUu"
            >Blood Runs Thicker /&nbsp;Warcraft: Legends Volume 4</a
          >
          –
          <strong
            ><span style="color: #800080">Manga / Graphic Novel</span
            >&nbsp;</strong
          >(Amazon)<strong>*4&nbsp;</strong>–
          <a
            href="https://amzn.to/2sJa7gX"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiJqQI"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2tlB7mW"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K6xvA0"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K2EYzO"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2lk5irq"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t8jnfR"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>27:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://eu.shop.battle.net/en-us/product/world-of-warcraft"
            >World of Warcraft: Wrath of the Lich King</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>*8</strong> – Check the [<u>25:</u>] World of
          Warcraft: Classic / Vanila section for explanation -&gt;<strong
            ><a href="https://www.g2a.com/r/umren-battlechest-us"
              >Battlechest for US</a
            >&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/umren-battlechest-eu"
            ><strong>Battlechest for EU</strong></a
          ><strong>&nbsp;– </strong>Use Discount Code: <strong>UMREN</strong> at
          checkout for lowest price!
        </li>
        <li>
          <a
            href="https://amzn.to/2kSiqUa"
            target="_blank"
            rel="noopener noreferrer"
            >Night of the Dragon</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2kSiqUa"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JP0VTT"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2liAhnu"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lgEOH7"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2I33D28"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2MCIcsn"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tkhmMB"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2JtoujV"
            target="_blank"
            rel="noopener noreferrer"
            >Stormrage</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon)-
          <a
            href="https://amzn.to/2JtoujV"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I06glq"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2t7tnpw"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2I3Woab"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2I35zYv"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2lgEYOJ"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2ypS3P2"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2JsRiJz"
            target="_blank"
            rel="noopener noreferrer"
            >The Shattering: Prelude to Cataclysm</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span>(Amazon)-
          <a
            href="https://amzn.to/2JsRiJz"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2t9z9H9"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2K2j7IR"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2t9mzYp"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K082YH"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2JOd6A9"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2JOGcQ7"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/lorthemar-theron"
            >Lor’themar Theron: In the Shadow of the Sun</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/lilis-travel-journal"
            >Li Li’s Travel Journal (1-4)</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span
          >(Free)
        </li>
        <li>
          <a href="https://wow.gamepedia.com/Glory_(short_story)">Glory</a>
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/garrosh-hellscream"
            >Garrosh Hellscream: Heart of War</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span
          >(Free)
        </li>
        <li>
          <a
            href="https://www.comixology.com/World-of-Warcraft-Special-1/digital-comic/10183?ref=c2l0ZS9saXN0L2Rlc2t0b3AvZ3JpZExpc3QvSXNzdWVz"
            >World of Warcraft Special #1</a
          >
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel </strong></span
          >(Comixology)
        </li>
        <li>
          <a
            href="https://amzn.to/2Jti2cT"
            target="_blank"
            rel="noopener noreferrer"
            >Mage</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon) –
          <a
            href="https://amzn.to/2Jti2cT"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2yl4xr6"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M5rXCZ"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2t7bK9d"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2ymAakd"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tkxWfA"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tkxyh8"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2ldOOkn"
            >Fallen / Warcraft: Legends Volume 1</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong>*1</strong> –
          <a
            href="https://amzn.to/2HodYWf"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2lgUCcR"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MzWVnL"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tl1IRl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2thgQPD"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M4J87w"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I2YxTB"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2tfds7L"
            >Fear / Warcraft: Legends Volume 2&nbsp;</a
          >–
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon) <strong>*2&nbsp;</strong>–
          <a
            href="https://amzn.to/2LmiPJV"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ytJtyG"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JSTnQ4"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2HZQBTa"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K6xHze"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tl3922"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t3Xxdl"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2JLGfMw"
            >Fiend 3 / Warcraft: Legends Volume 3</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong> *3&nbsp;</strong>–
          <a
            href="https://amzn.to/2JyBSU3"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2JVJT2o"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2I3oCSl"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkszNl"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2lhIcl2"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2K0uaix"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2ynTTQw"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2JKdnnH"
            >Fate 4 / Warcraft: Legends Volume 4</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong> *4&nbsp;</strong>–
          <a
            href="https://amzn.to/2sJa7gX"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiJqQI"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2tlB7mW"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K6xvA0"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K2EYzO"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2lk5irq"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t8jnfR"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2sL8yz1"
            target="_blank"
            rel="noopener noreferrer"
            >Pearl of Pandaria</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2sL8yz1"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2MDKFm9"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ypTrkI"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2I3XC5h"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JLBLVY"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2t96lP7"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I34UGs"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2M1c6Fl"
            >Nightmares 5 / Warcraft: Legends Volume 5</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)<strong> *5&nbsp;</strong>–
          <a
            href="https://amzn.to/2M0MtWl"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2tiYv4B"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M3GZcn"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lmBIl7"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2liq9uY"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a href="https://amazon.it" target="_blank" rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2lhCwHD"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2xKpwUf"
            target="_blank"
            rel="noopener noreferrer"
            >Shaman</a
          >
          –
          <span style="color: #800080"
            ><strong>Manga / Graphic Novel </strong></span
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2xKpwUf"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I37H2r"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JQKLcm"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2ymRXrx"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K3hWIZ"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2yo9y2n"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2yo90JR"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=Ch4rc5W4dKY"
            >The Wrathgate</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span
          >&nbsp;– <strong>World of Warcraft Cinematic</strong>
        </li>
      </ul>
      <p>
        <strong><u>28:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://eu.shop.battle.net/en-us/product/world-of-warcraft"
            >World of Warcraft: Cataclysm</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>*8</strong> – Check the [<u>25:</u>] World of
          Warcraft: Classic / Vanila section for explanation -&gt;<strong
            ><a href="https://www.g2a.com/r/umren-battlechest-us"
              >Battlechest for US</a
            >&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/umren-battlechest-eu"
            ><strong>Battlechest for EU</strong></a
          ><strong>&nbsp;– </strong>Use Discount Code: <strong>UMREN</strong> at
          checkout for lowest price!
        </li>
        <li>
          <a
            href="https://amzn.to/2sBDqTm"
            target="_blank"
            rel="noopener noreferrer"
            >Wolfheart</a
          >
          –
          <span style="color: #993300"><strong>Novel</strong></span>
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2sBDqTm"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I1qJ9w"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JRF5yU"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K6tbR5"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K1DDJE"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2tkw86e"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2I08KjK"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2Lot5kS">Thral: Twilight of the Aspects</a>
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)&nbsp;– US | UK | CA | DE | FR | IT | ES
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/gelbin-mekkatorque"
            >Gelbin Mekkatorque: Cut Short</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span>
          (Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/sylvanas-windrunner"
            >Sylvanas Windrunner: Edge of Night</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span>
          (Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/tyrande-whisperwind"
            >Tyrande &amp; Malfurion: Seeds of Faith</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/council-of-three-hammers"
            >The Council of Three Hammers: Fire and Iron</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span>
          (Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/gallywix"
            >Gallywix: Trade Secrets of a Trade Prince</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/baine-bloodhoof"
            >Baine Bloodhoof: As Our Fathers Before Us</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/genn-greymane"
            >Genn Greymane: Lord of His Pack</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/velen"
            >Velen: Prophet’s Lesson</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/leader-story/varian-wrynn"
            >Varian Wrynn: Blood of Our Fathers</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/charge-of-the-aspects"
            >Charge of the Aspects</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span>
          (Free)
        </li>
        <li>
          <a
            href="https://amzn.to/2JfnEbh"
            target="_blank"
            rel="noopener noreferrer"
            >Curse of the Worgen</a
          >
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel</strong></span
          >
          (Amazon) –
          <a
            href="https://amzn.to/2JfnEbh"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2K2rlAQ"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2LYoxSk"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JZ5kPS"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2liVjCD"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2I1mb2Q"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t8CSF0"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a href="https://amzn.to/2syAyXG">Bloodsworn</a>
          –
          <span style="color: #ff00ff"
            ><strong>Comic / Graphic Novel </strong></span
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2syAyXG"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2ynllOh"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2K5Bht8"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2t9plwN"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JYZlL2"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2I3ad8W"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2JXuNta"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2HoOzLY"
            target="_blank"
            rel="noopener noreferrer"
            >Dark Riders</a
          >
          –
          <strong
            ><span style="color: #800080">Manga / Graphic Novel</span
            >&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2HoOzLY"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2K2t6xW"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JSqZ0b"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JXMBrt"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2tqDVQ7"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M1C8Ze"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2tkzeHv"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
      </ul>
      <p>
        <strong><u>30:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://eu.shop.battle.net/en-us/product/world-of-warcraft"
            >World of Warcraft: Mists of Pandaria</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>*8</strong> – Check the [<u>25:</u>] World of
          Warcraft: Classic / Vanila section for explanation -&gt;<strong
            ><a href="https://www.g2a.com/r/umren-battlechest-us"
              >Battlechest for US</a
            >&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/umren-battlechest-eu"
            ><strong>Battlechest for EU</strong></a
          ><strong>&nbsp;– </strong>Use Discount Code: <strong>UMREN</strong> at
          checkout for lowest price!
        </li>
        <li>
          <a
            href="https://amzn.to/2xMb8ed"
            target="_blank"
            rel="noopener noreferrer"
            >Jaina Proudmoore: Tides of War</a
          >
          –
          <span style="color: #993300"><strong>Novel </strong></span
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2xMb8ed"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I2k3ru"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2JP6SjH"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2I1rSOm"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2JYRcq5"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2ymUySj"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2M5N0oP"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2t77Mxq"
            target="_blank"
            rel="noopener noreferrer"
            >Dawn of the Aspects</a
          >
          –
          <span style="color: #993300"><strong>Novel</strong></span>
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2t77Mxq"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2lkXYvy"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2I09a9D"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2lkS25L"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2MAoBZH"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2M393fV"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2M5LYth"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2tar2dr"
            target="_blank"
            rel="noopener noreferrer"
            >Vol’jin: Shadows of the Horde</a
          >
          –
          <span style="color: #993300"><strong>Novel</strong></span>
          (Amazon)&nbsp;–
          <a
            href="https://amzn.to/2tar2dr"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a href="https://amzn.to/2yocyM4">UK</a>
          |
          <a href="https://amzn.to/2t9psIU">CA</a>
          |
          <a href="https://amzn.to/2M5vzoF">DE</a>
          |
          <a href="https://amzn.to/2yqwTAw">FR</a>
          |
          <a href="https://amzn.to/2I1fAoU">IT</a>
          |
          <a href="https://amzn.to/2I1fgXe">ES</a>
        </li>
        <li>
          <a
            href="http://media.blizzard.com/wow/lore/quest-for-pandaria/novella/part-1/quest-for-pandaria-part-1_en-us.pdf"
            >Quest for Pandaria 1</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story </strong></span>(Free
          PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/F4952B99G6CE1474321631366.pdf"
            >Quest for Pandaria 2</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/VIUU60BCAFB51474321631380.pdf"
            >Quest for Pandaria 3</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/47IUELLFMZ9E1474321631452.pdf"
            >Quest for Pandaria 4</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/lilis-travel-journal"
            >Li Li’s Travel Journal (5-11)</a
          >
          –
          <span style="color: #ff9900"><strong>Short Story</strong></span
          >&nbsp;(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/red-blossoms"
            >The Trial of the Red Blossoms</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/strength-of-steel"
            >The Strength of Steel</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/bleeding-sun"
            >Bleeding Sun</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/blank-scroll"
            >The Blank Scroll</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/over-water"
            >Over Water</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
      </ul>
      <p>
        <strong><u>31:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a href="https://eu.shop.battle.net/en-us/product/world-of-warcraft"
            >World of Warcraft: Warlords of Draenor</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>*8</strong> – Check the [<u>25:</u>] World of
          Warcraft: Classic / Vanila section for explanation -&gt;<strong
            ><a href="https://www.g2a.com/r/umren-battlechest-us"
              >Battlechest for US</a
            >&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/umren-battlechest-eu"
            ><strong>Battlechest for EU</strong></a
          ><strong>&nbsp;– </strong>Use Discount Code: <strong>UMREN</strong> at
          checkout for lowest price!
        </li>
        <li>
          <a
            href="https://amzn.to/2JWCUG9"
            target="_blank"
            rel="noopener noreferrer"
            >War Crimes</a
          >
          – <span style="color: #993300"><strong>Novel</strong></span> –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2JWCUG9"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2I0ncIw"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ME55Ma"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2JZpHjr"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2t6QAs4"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2JZSu7t"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K3FT32"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/short-story/destination-pandaria/jade-hunters"
            >The Jade Hunters</a
          >
          –&nbsp;<strong
            ><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/VF7QQ84KSVFK1474321631684.pdf"
            >The Untamed Valley</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="http://us.battle.net/wow/en/game/lore/savage-world/hellscream/page-1"
            >Hellscream</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="http://us.battle.net/wow/en/game/lore/savage-world/code-of-rule/page-1"
            >Code of Rule</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="http://us.battle.net/wow/en/game/lore/savage-world/apocrypha/page-1"
            >Apocrypha</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/Z5EDRREGHRLM1406310705908.pdf"
            >Gul’dan and the Stranger</a
          >
          –
          <strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/template_resource/O0YJSSDNAZW31412099425685.pdf"
            >Blackhand</a
          >
          –
          <strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://wow.gamepedia.com/g00/Lords_of_War?i10c.encReferrer=&amp;i10c.ua=1&amp;i10c.dv=14"
            >Lords of War</a
          >
          – <strong>Animated Mini Video Series</strong>&nbsp;(Free Audio Books)
        </li>
      </ul>
      <p>
        <strong><u>32:</u></strong>
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://eu.shop.battle.net/en-us/product/world-of-warcraft-legion"
            >World of Warcraft: Legion</a
          >
          –
          <span style="color: #008000"><strong>Game </strong></span
          >(Blizz)<strong>&nbsp;– </strong>Check above for explanation, it’s
          quite similar from BattleChest to Legion expansion&nbsp;-&gt;<a
            href="https://www.g2a.com/r/legion-us-cheap-2018"
            ><strong>Legion US</strong></a
          ><strong>&nbsp;&lt;|&gt; </strong
          ><a href="https://www.g2a.com/r/legion-eu-cheap-2018"
            ><strong>Legion EU</strong></a
          >– Use Discount Code: <strong>UMREN</strong> at checkout for lowest
          price!
        </li>
        <li>
          <a
            href="https://amzn.to/2I09WU5"
            target="_blank"
            rel="noopener noreferrer"
            >Ilidan</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2I09WU5"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2M63Ofp"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2K08GlR"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2tkJJui"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2yl68NW"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2K3G6mQ"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2K5VCOY"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2ylimWw"
            target="_blank"
            rel="noopener noreferrer"
            >Traveler</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2ylimWw"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2lkZfmk"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2ynPey9"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2K3wEzO"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K3wdp5"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2MFk6gL"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2JN6JND"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2MD1bD9"
            target="_blank"
            rel="noopener noreferrer"
            >Traveler: The Spiral Path</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2MD1bD9"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2K5OaDw"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2M12sme"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2teFU9U"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2ynAMpH"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2yjULpg"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2t9yCF3"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://amzn.to/2M4MN5y"
            target="_blank"
            rel="noopener noreferrer"
            >Before the Storm</a
          >
          –
          <strong><span style="color: #993300">Novel</span>&nbsp;</strong
          >(Amazon)&nbsp;–
          <a
            href="https://amzn.to/2M4MN5y"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >
          |
          <a
            href="https://amzn.to/2K5ATem"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2I1OuOL"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2yqzHO4"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2K3x0q8"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2lhfuRf"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2toSB2a"
            target="_blank"
            rel="noopener noreferrer"
            >ES</a
          >
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/news/20704258/warcraft-short-story-dark-mirror"
            >Dark Mirror</a
          >
          –
          <strong><span style="color: #ff9900">Short Story</span>&nbsp;</strong
          >(Free)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/tomb-of-sargeras-part-1"
            >The Tomb of Sargeras [Part 1]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/tomb-of-sargeras-part-2"
            >The Tomb of Sargeras [Part 2]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/tomb-of-sargeras-part-3"
            >The Tomb of Sargeras [Part 3]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/tomb-of-sargeras-part-4"
            >The Tomb of Sargeras [Part 4]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/thousand-years-of-war-part-1"
            >A Thousand Years of War [Part 1]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/thousand-years-of-war-part-2"
            >A Thousand Years of War [Part 2]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/audio/thousand-years-of-war-part-3"
            >A Thousand Years of War [Part 3]</a
          >
          –
          <strong
            ><span style="color: #999999">Audio&nbsp;Short Story</span
            >&nbsp;</strong
          >(Free Audio Books)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/content_entry_media/dw/DWKY1JKOS8T81467245399497.pdf"
            >Magni: Fault Lines</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/content_entry_media/lo/LORGQDHNUKW51467730559968.pdf"
            >Nightborne: Twilight of Suramar</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/content_entry_media/3r/3RVHS5WC7OYI1468444871129.pdf"
            >Highmountain: A Mountain Divided</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://bnetcmsus-a.akamaihd.net/cms/content_entry_media/io/IOBVHZGUWSES1469116501571.pdf"
            >Anduin: Son of the Wolf</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/animation/harbingers-guldan"
            >Harbingers: Gul’dan</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/animation/harbingers-khadgar"
            >Harbingers: Khadgar</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/animation/harbingers-illidan"
            >Harbingers: Illidan</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>
        <span style="text-decoration: underline"><strong>33:</strong></span>
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/comic/jaina-reunion"
            target="_blank"
            rel="noopener noreferrer"
            >Jaina: Reunion</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/comic/magni-the-speaker"
            target="_blank"
            rel="noopener noreferrer"
            >The Speaker</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://worldofwarcraft.com/en-us/story/comic/windrunner-three-sisters"
            target="_blank"
            rel="noopener noreferrer"
            >Three Sisters</a
          >
          –&nbsp;<strong
            ><span style="color: #ff00ff">Comic / Graphic Novel</span
            >&nbsp;</strong
          >(Free PDF Download)
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=Fo7XPvwRgG8"
            target="_blank"
            rel="noopener noreferrer"
            >Warbringers: Jaina</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=TVp7yLfnmrE"
            target="_blank"
            rel="noopener noreferrer"
            >Warbringers: Sylvanas</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=aW_h0qf9vpA"
            target="_blank"
            rel="noopener noreferrer"
            >Cinematic: “Old Warrior”</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=jSJr3dXZfcg"
            target="_blank"
            rel="noopener noreferrer"
            >Cinmatic: World of Warcraft – Battle for Azeroth</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=hndyTy3uiZM"
            target="_blank"
            rel="noopener noreferrer"
            >Warbringers: Azshara</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://eu.shop.battle.net/en-us/product/world-of-warcraft-battle-for-azeroth"
            target="_blank"
            rel="noopener noreferrer"
            >World of Warcraft: Battle for Azeroth</a
          >
          (Launches: August 14th 2018) –
          <span style="color: #008000"
            ><strong
              ><span style="color: #008000">Game</span>&nbsp;</strong
            ></span
          >(Blizz) – Read more on the Blizz Link on the left. If you want to
          check G2A Prices, which are mostly cheaper than Blizz Store, go on
          this link:&nbsp;<span style="color: #008000"
            ><strong
              ><a
                href="https://www.g2a.com/r/umren"
                target="_blank"
                rel="noopener noreferrer"
                >G2A Battle For Azeroth&nbsp;</a
              ></strong
            ></span
          >and search for Battle for Azeroth. Don’t forget to use
          “<strong>UMREN</strong>” as discount code at checkout to get the best
          price there is!
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=_5Kx7hJ-sXM"
            target="_blank"
            rel="noopener noreferrer"
            >The Siege of Lordaeron Has Begun</a
          >&nbsp;–&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
      </ul>
      <p>
        <span style="text-decoration: underline"
          ><strong
            ><span style="color: #3366ff; text-decoration: underline"
              >Alliance:</span
            ></strong
          ></span
        >
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://www.youtube.com/watch?v=3LMNG2UKuig"
            target="_blank"
            rel="noopener noreferrer"
            >[Spoiler] Arrival to Kul Tiras</a
          >&nbsp;–&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=XMPt6mxS3RY"
            >[Spoiler] Jaina’s Nightmare</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=NDj2aaxixxo"
            target="_blank"
            rel="noopener noreferrer"
            >[Spoiler] Realm of Torment</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=MKJ7lVbv3VM"
            target="_blank"
            rel="noopener noreferrer"
            >[Spoiler] The Return of Hope: Kul Tiras</a
          >
          –
          <span style="color: #ff0000"><strong>YouTube Video</strong></span>
        </li>
      </ul>
      <p>—————————————————————————————————————</p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://www.youtube.com/watch?v=3CGBLVNpoKo"
            target="_blank"
            rel="noopener noreferrer"
            >[Spoiler] The Siege of Lordaeron: Turn The Tide</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=nTowPRMsiEA"
            >[Spoiler] Lordaeron Throne Room Confrontation</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
      </ul>
      <p>—————————————————————————————————————</p>
      <p>
        <span style="text-decoration: underline; color: #ff0000"
          ><strong><span>Horde:</span></strong></span
        >
      </p>
      <ul style="list-style: none">
        <li>
          <a
            href="https://www.youtube.com/watch?v=Gc3045fMI9w"
            target="_blank"
            rel="noopener noreferrer"
            >[Spoiler] Arrival to Zandalar</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=rnNNCXndjCM"
            target="_blank"
            rel="noopener noreferrer"
            >[Spoiler] Rastakhan’s Deal with Bwonsamdi</a
          >&nbsp;–&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=nVLGwudbZTk"
            >[Spoiler] The Threat Within: Zandalar</a
          >
          –&nbsp;<span style="color: #ff0000"
            ><strong>YouTube Video</strong></span
          >
        </li>
      </ul>
      <p>
        *Note: There are couple of gaps in the story that are revealed by
        playing the game, and finishing scenarios. I haven’t read the books yet,
        maybe they cover some part of it, but those who will play Battle for
        Azeroth will experience the full detail story of it.
      </p>
      <p>
        <a
          href="https://amzn.to/2OECZRK"
          target="_blank"
          rel="noopener noreferrer"
          ></a>
      </p>
      <p>
        <strong>ALSO&nbsp;</strong>the <strong>New Books</strong> (Novels)
        <strong>“Elegy”</strong> and <strong>“A Good War”</strong> so far is
        known that they are coming as Physical Copy with&nbsp;<strong
          ><a
            href="https://amzn.to/2OJy0iS"
            target="_blank"
            rel="noopener noreferrer"
            >Battle for Azeroth Collector’s Edition</a
          >&nbsp;– (Amazon Links:
          <a
            href="https://amzn.to/2OJy0iS"
            target="_blank"
            rel="noopener noreferrer"
            >US</a
          >&nbsp;|&nbsp;<a
            href="https://amzn.to/2AAVBix"
            target="_blank"
            rel="noopener noreferrer"
            >UK</a
          >
          |
          <a
            href="https://amzn.to/2MhN9WO"
            target="_blank"
            rel="noopener noreferrer"
            >CA</a
          >
          |
          <a
            href="https://amzn.to/2O79fvO"
            target="_blank"
            rel="noopener noreferrer"
            >DE</a
          >
          |
          <a
            href="https://amzn.to/2n9z430"
            target="_blank"
            rel="noopener noreferrer"
            >FR</a
          >
          |
          <a
            href="https://amzn.to/2AyLquS"
            target="_blank"
            rel="noopener noreferrer"
            >IT</a
          >
          |
          <a
            href="https://amzn.to/2Kk53Xj"
            target="_blank"
            rel="noopener noreferrer"
            >ES </a
          >)</strong
        >&nbsp;but source for buying them in physical form is unknown to me.
        However they are offered as Free PDF Downloads from the Official World
        of Warcraft Page on the following links:&nbsp; (They open in New Tab)<br />
        –
        <strong
          ><a
            href="https://worldofwarcraft.com/en-us/story/short-story/a-good-war"
            target="_blank"
            rel="noopener noreferrer"
            >A Good War</a
          >&nbsp;</strong
        >–&nbsp;by Robert Brooks<br />
        –
        <strong
          ><a
            href="https://worldofwarcraft.com/en-us/story/short-story/elegy"
            target="_blank"
            rel="noopener noreferrer"
            >Elegy</a
          >&nbsp;</strong
        >– by Christie Golden
      </p>
      <p>
        If anyone of you knows a source, when it comes up, let me know in the
        comments below and I will edit the post again.
      </p>
      <p>&nbsp;</p>
      <p style="text-align: center">
        <strong><em>List is done! 😀</em></strong>
      </p>
      <p>&nbsp;</p>
      <h1>Now It’s Your Turn!</h1>
      <p>
        I have spent <strong>150+ hours</strong> on building this list for us
        guys… I<strong> would appreciate</strong> if you take
        <strong>30 seconds</strong> and Let me know down in the Comments, what
        you think about the list, whether it helped you, and if you have any
        feedback, or suggestion for the future edits.
      </p>
      <p>
        <strong>Also</strong>
        <strong>I am really interested</strong>&nbsp;which Part of the Warcraft
        Era you Liked the most?
      </p>
      <ul style="list-style: none">
        <li>
          Is the&nbsp;<strong>Beggining of the World?<br /> </strong>
        </li>
        <li>Some of the Warcraft Campaigns?</li>
        <li>
          Maybe World of Warcraft expansion? (Classic, TBC, Wrath, Cata, etc)?
        </li>
        <li>Any of it I didn’t mentioned?</li>
      </ul>
      <p>
        <strong>It would help the MOST</strong> for my future collection, and
        for me personally, what content you guys, were the most
        <strong>excited</strong> about!
      </p>
      <p>&nbsp;</p>
      <p><em>Sources:</em></p>
      <p>
        <a
          href="https://us.battle.net/forums/en/"
          target="_blank"
          rel="noopener noreferrer"
          >WoW Forums</a
        >
      </p>
      <p>
        <a
          href="https://wow.gamepedia.com/Wowpedia"
          target="_blank"
          rel="noopener noreferrer"
          >WoWpedia</a
        >
      </p>
      <p>
        <a href="https://youtube.com/umrentv">Youtube</a>
        / Game Feedback
      </p>
      <p>Hours and Hours Spent on Research &amp; Optimization!</p>
      <p>&nbsp;</p>
      <p>
        <span style="text-decoration: underline"
          ><em><strong>Edits:</strong></em></span
        >
      </p>
      <p>
        19.06.2018 – <strong>Thanks to all your feedback</strong> on
        <a
          href="https://discord.gg/umrentv"
          target="_blank"
          rel="noopener noreferrer"
          >Discord</a
        >
        and
        <a
          href="https://twitch.tv/umren"
          target="_blank"
          rel="noopener noreferrer"
          >Twitch</a
        >, I have modified some of the links that were broken, re-arranged 1-2
        books to fit better with the story, and upon your request I have added
        links for Different Regions to buy from Amazon as well. If there is
        anything else in future that is broken, can be better, or you just wanna
        give feedback, please… do it. I would love to modify stuffs, so we can
        make this list perfect. And just remember,&nbsp;<strong
          >You guys rock!</strong
        >
      </p>
      <p>
        03.08.2018 – Thanks to&nbsp;<span
          ><strong tabindex="0" role="button">Cassiopeia🦉&nbsp;</strong>on
          Discord, for updating me with the new books and trailers, in timely
          manner I managed to add them and update some other parts of the
          playlist too, with the Battle for Azeroth Content (Warbringers, New
          Books, Cinematics, etc).</span
        >
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h3 style="text-align: center">BTW:</h3>
      <p style="text-align: center">
        <i
          ><b> P.S. </b> I am Amazon Affiliate Participant, which helps me Earn
          Revenue by suggesting you Products from Amazon. If you are using
          Amazon, <b>Best Way to Support my Content</b> is by using my Links
          while you are shopping anyway. That way <b>You Get the Best Deals</b>,
          and I Get to <b>Keep Doing What I Love</b>, Producing Content &amp;
          Helping you Guys Out! <b> Win-Win, right? 😀 </b></i
        >
      </p>
      <p style="text-align: center">
        Select Your Region &amp; Get the Best Prices with Today’s Deals:
        <a
          href="https://amzn.to/2LQt3T1"
          target="_blank"
          rel="noopener noreferrer"
          >United States</a
        >
        |
        <a
          href="https://amzn.to/2MvQIcH"
          target="_blank"
          rel="noopener noreferrer"
          >United Kingdom</a
        >
        |
        <a
          href="https://amzn.to/2JD7Hf8"
          target="_blank"
          rel="noopener noreferrer"
          >Canada</a
        >
        |
        <a
          href="https://amzn.to/2laVP5p"
          target="_blank"
          rel="noopener noreferrer"
          >Germany</a
        >
        |
        <a
          href="https://amzn.to/2la8HZI"
          target="_blank"
          rel="noopener noreferrer"
          >France</a
        >
        |
        <a
          href="https://amzn.to/2ygzi0l"
          target="_blank"
          rel="noopener noreferrer"
          >Italy</a
        >
        |
        <a
          href="https://amzn.to/2let1sN"
          target="_blank"
          rel="noopener noreferrer"
          >Spain</a
        >
      </p>
      <p style="text-align: center">
        <b>Thank you</b> for your Continous &amp; Amazing Support, so far, and
        in future! <b>It means the World to Me! &lt;3 </b>
      </p>
    </div>
  </article>
`,
};
